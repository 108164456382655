<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">Event</h2></div>
            <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
        </div>
        <el-divider class="mv-10 o-020"></el-divider>
        <vue-scroll :settings="settings" class="page" :style="{height: scrollHeight + 'px'}">
          <div class="flex column gaps pr-20">
              <div class="box grow flex gaps">
                <div class="box left center" style="width: 144px;">
                    <h4 class="mt-10 mb-5 o-050">CONTACT PERSON</h4>
                </div>
                <div class="box">
                  <el-select :popper-append-to-body="false" style="min-width: 180px;"
                  v-model="person" placeholder="Select Person" @change="handleChangePerson">
                    <el-option
                      v-for="item in basketWaiwers"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="box">
              <div class="box grow">
                  <el-form label-position="left" :rules="contactRules" ref="contact" label-width="160px" :model="contact" size="small">
                      <el-form-item label="First name" prop="first">
                          <el-input v-model="contact.first"></el-input>
                      </el-form-item>
                      <el-form-item label="Surname" prop="surname">
                          <el-input v-model="contact.surname"></el-input>
                      </el-form-item>
                      <el-form-item label="Phone" prop="phone">
                          <el-input v-model="contact.phone"></el-input>
                      </el-form-item>
                      <el-form-item label="Email" prop="email">
                          <el-input v-model="contact.email"></el-input>
                      </el-form-item>
                  </el-form>
              </div>
              <el-divider class="mv-10 o-020"></el-divider>
              <div class="box">
                  <el-form label-position="left" :rules="rules" ref="form" label-width="160px" :model="form" size="small">
                      <el-form-item label="Event Name" prop="name">
                          <el-input v-model="form.name"></el-input>
                      </el-form-item>
                      <el-form-item label="Date" prop="date">
                          <!-- <el-input v-model="form.date"></el-input> -->
                        <el-date-picker
                          :append-to-body="false"
                          v-model="form.date"
                          type="datetime"
                          placeholder="Select date and time"
                          default-time="12:00:00">
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="Persons" prop="persons">
                          <el-input-number :controls="false" v-model="form.persons"></el-input-number>
                      </el-form-item>
                      <el-form-item label="Cost" prop="cost">
                          <el-input-number :controls="false" v-model="form.cost"></el-input-number>
                      </el-form-item>
                      <el-form-item label="Comment" prop="comment">
                          <el-input type="textarea" v-model="form.comment"></el-input>
                      </el-form-item>
                      <el-form-item prop="file">
                          <el-upload
                            class="upload-demo"
                            :action="url + 'fileup'"
                            :headers="headerInfo"
                            :on-change="onChangeUpload"
                            :limit="1"
                            :file-list="fileList">
                            <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div>
                            <el-button size="small">Click to upload</el-button>
                          </el-upload>
                      </el-form-item>
                              <el-divider class="mv-10 o-020"></el-divider>
                      <el-form-item>
                          <el-button type="primary" size="default" @click="submit('form')">Submit</el-button>
                      </el-form-item>
                  </el-form>
                </div>
            </div>
          </div>
        </vue-scroll>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SaleEvents',

  props: {
    basket: {
      type: Array,
      default: function () {
        return []
      }
    },
    members: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      url: process.env.VUE_APP_BACKEND_URL,
      headerInfo: {
        'access-token': localStorage.getItem('token')
      },
      settings: {
        maxScrollbarLength: 60
      },
      contact: {
        first: '',
        surname: '',
        email: '',
        phone: ''
      },
      fileList: [],
      person: '',
      form: {
        date: '',
        cost: 2250,
        persons: 15,
        comment: '',
        fileName: ''
      },
      contactRules: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        surname: [
          { required: true, message: 'Please input surname', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: 'Please input phone number', trigger: 'blur' }
        ]
      },
      rules: {
        name: [
          { required: true, message: 'Please input event name', trigger: 'blur' }
        ],
        date: [
          { required: true, message: 'Please select date and time', trigger: 'blur' }
        ],
        cost: [
          { required: true, message: 'Please input cost', trigger: 'blur' }
        ],
        persons: [
          { required: true, message: 'Please input number of persons', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      events: state => state.event.events
    }),
    ...mapGetters([
      'basketWaiwers'
    ]),
    currentBusketItem () {
      let row = this.basket.find(b => b.basketId === this.$route.params.id)
      return row !== undefined ? row : {
        name: 'Event',
        desc: [],
        times: 0,
        rate: 0
      }
    },
    scrollHeight () {
      return this.innerHeight - 139
    }
  },
  methods: {
    onChangeUpload (file) {
      this.form.fileName = file.response
    },
    handleChangePerson (value) {
      let member = this.members.find(m => m.uid === value)
      if (member !== undefined) {
        Object.assign(this.contact, {
          first: member.first,
          surname: member.surname,
          email: member.email,
          phone: member.phone
        })
      } else if (value === '') {
        Object.assign(this.contact, {
          first: '',
          surname: '',
          email: '',
          phone: ''
        })
      }
    },
    submit () {
      this.$refs['contact'].validate((valid) => {
        if (valid) {
          this.$refs['form'].validate((validForm) => {
            if (validForm) {
              this.form.contact = this.contact
              // Object.assign(this.form, { contact: this.contact })
              this.$store.dispatch('addEvent', this.form)
                .then(() => {
                  this.$message({
                    showClose: true,
                    message: 'New event created successfully',
                    type: 'success'
                  })
                })
                .then(() => {
                  this.$onCommandParams('listOfEvents')
                })
            }
          })
        }
      })
    }
  }
}
</script>
